<script setup lang="ts">
import { onMounted } from "vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VLandingSectionLogo from "~/containers/landing/VLandingSectionLogo.vue";
import VLandingSectionWithCard from "~/containers/landing/VLandingSectionWithCard.vue";
import VLandingSectionMap from "~/containers/landing/VLandingSectionMap/VLandingSectionMap.vue";
import VLandingSectionContacts from "~/containers/landing/VLandingSectionContacts.vue";
import VLandingSectionCare from "~/containers/landing/VLandingSectionCare.vue";
import VLandingSectionProfits from "~/containers/landing/VLandingSectionProfits.vue";
import VLandingSectionProducts from "~/containers/landing/VLandingSectionProducts.vue";
import VLandingButtonLogin from "~/containers/landing/VLandingButtonLogin.vue";

definePageMeta({
  layout: "landing",
});

const { send } = AnalyticManager;

onMounted(() => {
  send("Landing:View");
});
</script>

<template>
  <div class="page-landing">
    <VLandingSectionLogo />
    <VLandingSectionProducts />
    <VLandingSectionProfits />
    <VLandingSectionCare />
    <VLandingSectionWithCard />
    <VLandingSectionMap />
    <VLandingSectionContacts />
    <VLandingButtonLogin />
  </div>
</template>

<style scoped lang="postcss">
.page-landing {
  padding-bottom: 96px;
}

@media (--pl-viewport-from-m) {
  .page-landing {
    padding-bottom: 0;
  }
}
</style>
