<script setup lang="ts">
import { debounce } from "lodash-es";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VSearch from "@magnit/core/src/containers/VSearch/VSearch.vue";
import VSegment from "@magnit/core/src/components/VSegment/VSegment.vue";
import VSegmentControl from "@magnit/core/src/components/VSegmentControl/VSegmentControl.vue";
import VShopSelect from "@magnit/unit-shop-select/src/VShopSelect.vue";
import {
  MAP_BALLOON_OPTIONS_INFO,
  LABEL_SHOP_SEARCH_PLACEHOLDER,
} from "@magnit/unit-shop-select/src/constants";
import VLandingWrapper from "~/containers/landing/VLandingWrapper.vue";

enum ShowType {
  List = "list",
  Map = "map",
}

const {
  mapBounds,
  targetShopRef,
  shopSearchQuery,
  mapLoading,
  storesListRef,
  yandexMapKey,
  listLoading,
  selectedStoreLocation,

  handleMapBoundsChange,
  handleSearchQueryChange,
} = useShopSelect();

const showType = ref<ShowType>(ShowType.Map);

const handleSubmitStore = (code: string) => {
  const entry = storesListRef.value.find((s) => s.code === code);
  if (!entry) return;
  showType.value = ShowType.Map;
  targetShopRef.value = entry;
};
const debouncedHandleSearchQueryChange = debounce(
  () => handleSearchQueryChange(),
  STORE_SEARCH_INPUT_DEBOUNCE,
);

watch(shopSearchQuery, debouncedHandleSearchQueryChange);
</script>

<template>
  <section id="landing-map" class="section-map">
    <VLandingWrapper class="section-map__title">
      <div class="section-map__title__container">
        <img src="/images/main-landing/map-pin.webp" alt="Map pin">
        <div class="section-map__title-wrapper">
          <VText tag="h2" :font="{ xs: 'title-large', ml: 'headline-large' }">
            Магазины на карте
          </VText>
        </div>
      </div>
    </VLandingWrapper>

    <div class="section-map__tools">
      <VLandingWrapper>
        <div class="section-map__tools__container">
          <VSegmentControl theme="light">
            <VSegment v-model="showType" :value="ShowType.Map">
              На карте
            </VSegment>
            <VSegment v-model="showType" :value="ShowType.List">
              Списком
            </VSegment>
          </VSegmentControl>
          <VSearch
            v-model="shopSearchQuery"
            class="section-map__list__search"
            :submit-visible="false"
            :placeholder="LABEL_SHOP_SEARCH_PLACEHOLDER"
          />
        </div>
      </VLandingWrapper>
    </div>

    <VLandingWrapper
      v-show="showType === ShowType.List"
      class="section-map__list"
    >
      <div class="section-map__list__container">
        <VShopsList
          :shops="storesListRef"
          :loading="listLoading"
          @submit:store="handleSubmitStore"
        />
      </div>
    </VLandingWrapper>

    <div v-show="showType === ShowType.Map" class="section-map__map">
      <VLandingWrapper>
        <ClientOnly>
          <VShopSelect
            class="section-map__map__container v1-shop-select"
            :yandex-map-key="yandexMapKey"
            :map-loading="mapLoading"
            :bounding-box="mapBounds"
            :display-modal="false"
            :display-close-button="false"
            :center="selectedStoreLocation"
            :shops="storesListRef"
            :target-shop="targetShopRef"
            :zoom="8"
            :balloon-options="MAP_BALLOON_OPTIONS_INFO"
            @submit:bounding-box="handleMapBoundsChange"
          />
        </ClientOnly>
      </VLandingWrapper>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.section-map {
  &__title {
    background-color: rgb(241 15 64 / 7%);
    padding-top: 24px;
    padding-bottom: 24px;

    @media (--pl-viewport-from-ml) {
      background-color: transparent;
    }

    &-wrapper {
      @media (--pl-viewport-from-ml) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__container {
      position: relative;
      max-width: 765px;

      img {
        width: 60px;
        position: absolute;
        top: -90px;
        left: 0;

        @media (--pl-viewport-from-ml) {
          position: relative;
          top: 0;
          width: 70px;
          margin-bottom: 4px;
        }
      }
    }
  }

  &__tools {
    padding: var(--pl-unit-x3) 0 var(--pl-unit-x2) 0;

    &__container {
      max-width: 765px;
      display: flex;
      flex-direction: column;
      gap: var(--pl-unit-x3);
    }
  }

  &__map {
    position: relative;

    &__container {
      max-width: 765px;
      margin: 0 -24px;
      position: relative;
      height: 578px;
      z-index: var(--pl-z-index-level1);

      @media (--pl-viewport-from-ml) {
        margin: 0;
      }
    }
  }

  &__list {
    &__container {
      max-width: 765px;
    }

    &__search {
      margin-bottom: var(--pl-unit-x2);
    }
  }

  .shops-map {
    & :deep(.shop-select__map) {
      width: 100%;
      height: 578px;
    }

    & :deep(.shop-select__map__ymap),
    & :deep(.shop-select__map__outer),
    & :deep(.shop-select__map) {
      inset: unset;
      position: static;
    }

    & :deep(.shop-select__map__container) {
      inset: unset;
      position: relative;
    }

    & :deep(.shop-select__map__balloon__submit) {
      display: none;
    }

    & :deep(.shop-select__toolbar__outer) {
      z-index: var(--pl-z-index-level1);
    }
  }
}
</style>
